let KIDBIZ_HOST = '';
let UPLOADER_HOST = '';

let kbUrl = process.env.REACT_APP_KIDBIZ_HOST
if (process.env.REACT_APP_ENV !== 'local') {
  kbUrl = window.location.origin;
}
KIDBIZ_HOST = kbUrl;
UPLOADER_HOST = process.env.REACT_APP_UPLOADER_HOST;

const config = {
  KIDBIZ_HOST,
  UPLOADER_HOST,
};
export default config;
